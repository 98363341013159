import { Accordion, AccordionDetails, AccordionSummary, Divider, FormControl, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
//import { trace } from 'mobx';
import { AppState, UserState, RatingState } from '../state';
import { UpdateRating } from './updateRating';
import { ItemRating } from './itemRating';
import '../Styles.scss';
import { ExpandMore } from '@mui/icons-material';
import { Result } from '../models';

interface RatingDetailProps {
    ratingState: RatingState;
    appState: AppState;
    userState: UserState;
    isManualEntry?: boolean;
    onHandleClose: () => void;
    onRestaurantNameChanged?: (name: string) => void;
    onHandleResult: (result: Result) => void;
    onIsLoading: (isLoading: boolean) => void;
}

export const RatingDetail = observer((props: RatingDetailProps) => {
    //trace(true);
    const handleChangeItem = (event) => {
        const selectedItemId = event.target.value;
        const foundItem = props.ratingState.items.find(i => i.id === selectedItemId)!;
        props.ratingState.setSelectedItem(foundItem);
    }

    const handleNewRestaurantNameChange = (event) => {
        const name = event.target.value;
        if (props.onRestaurantNameChanged) {
            props.onRestaurantNameChanged(name);
        }
    }

    return (
        <Paper id='rating-detail-container-paper'>
            <div className='flex-column' id='rating-detail-container-div'
                style={{
                    padding: '20px',
                    gap: '10px'
                }}>
                {props.isManualEntry && props.ratingState.selectedRestaurant?.id === 'placeholder'
                    ?
                    <TextField
                        id='add-new-restaurant-manually-textfield'
                        aria-describedby='Text field to input a manual restaurant name'
                        label='Restaurant/bar name'
                        value={props.ratingState.selectedRestaurant?.name}
                        onChange={handleNewRestaurantNameChange}
                    />
                    :
                    <Typography
                        fontSize={'1.4em'}>
                        {props.ratingState.selectedRestaurant?.name}
                    </Typography>
                }
                <Divider />
                {props.ratingState.selectedRestaurant?.id.toLowerCase() !== 'placeholder' &&
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='community-rating-accordion-content'
                            id='community-rating-accordion'
                            sx={{ flexDirection: 'row-reverse' }}
                        >
                            <div className='flex-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Typography sx={{ width: '50%', flexShrink: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                    Overall Rating
                                </Typography>
                                <ItemRating
                                    isReadOnly={true}
                                    showRatingValue={true}
                                    showRatingOnly={true}
                                    rating={props.ratingState.overallRatingForSelectedItemAndRestaurant?.overallRating}
                                    size={'small'}
                                    isTitleBold={true}
                                />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <>
                                <FormControl variant='standard' sx={{ m: 0, minWidth: 120, justifyContent: 'center', flex: 2, marginBottom: '10px' }}>
                                    <Select
                                        labelId='select-item-while-rating-label'
                                        id='select-item-in-rating-popup'
                                        value={props.ratingState.selectedItem?.id || ''}
                                        onChange={handleChangeItem}>
                                        {
                                            props.ratingState.items.map(item => <MenuItem key={`select-popup-item-${item.name}`} value={item.id}>{item.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <div style={{ maxHeight: '300px', gap: '5px', overflow: 'auto' }}>
                                    {props.ratingState.readonlyRatingsForSelectedItemAndRestaurant.map(r =>
                                        <ItemRating
                                            key={`rating-${r.user.name}`}
                                            isReadOnly={true}
                                            showRatingValue={true}
                                            title={r.user.name || r.user.email}
                                            rating={r.rating || 0}
                                            size={'small'}
                                        />)}
                                </div>
                            </>
                        </AccordionDetails>
                    </Accordion>
                }
                <Divider />
                <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Your Rating</Typography>
                <UpdateRating
                    ratings={props.ratingState.myRatingsForSelectedRestaurant}
                    appState={props.appState}
                    ratingState={props.ratingState}
                    onHandleResult={props.onHandleResult}
                    onIsLoading={props.onIsLoading}
                    onHandleClose={props.onHandleClose} />
            </div>
        </Paper>
    )
});
