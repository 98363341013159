import { computed, makeAutoObservable } from 'mobx';
import { Subject, take, takeUntil } from 'rxjs';
import { OverallRating, Item, Rating, Restaurant, Result } from '../models';
import { RatingService } from '../services';
import { manhattanLowercase } from '../util';
import { AppState } from './appState';

export class RatingState {
    items: Item[] = [];
    restaurants: Restaurant[] = [];
    ratings: Rating[] = [];
    myRatings: Rating[] = [];
    selectedRestaurant: Restaurant | undefined | null;
    selectedItem: Item | null | undefined;

    constructor(private ratingService: RatingService, private appState: AppState, private stop$: Subject<boolean>) {
        makeAutoObservable(this, {
            overallRatings: computed,
            getDistinctRestaurantsForItem: false,
            getRatingsForItem: false,
            overallRatingForSelectedItemAndRestaurant: computed,
            myRatingsForSelectedRestaurant: computed,
            readonlyRatingsForSelectedItemAndRestaurant: computed
        });

        const selectedItemIdFromConfig = localStorage.getItem('selectedItemId');
        this.ratingService.allItems$.pipe(
            take(1)
        ).subscribe(items => {
            if (selectedItemIdFromConfig) {
                const foundItem = items.find(i => i.id === selectedItemIdFromConfig)!;
                this.setSelectedItem(foundItem);
            } else {
                const manhattan = items.find(i => i.name.toLowerCase() === manhattanLowercase)!;
                this.setSelectedItem(manhattan);
            }
        });

        this.ratingService.allItems$.pipe(
            takeUntil(this.stop$)
        ).subscribe(items => {
            const sortedArray = [
                items.find(i => i.name.toLowerCase() === manhattanLowercase)!,
                ...items.filter(i => i.name.toLowerCase() !== manhattanLowercase)
            ];
            this.setItems(sortedArray);
        });

        this.ratingService.allRatings$.pipe(
            takeUntil(this.stop$)
        ).subscribe(this.setRatings);

        this.ratingService.allRestaurants$.pipe(
            takeUntil(this.stop$)
        ).subscribe(this.setRestaurants);

        this.ratingService.myRatings$.pipe(
            takeUntil(this.stop$)
        ).subscribe(this.setMyRatings);
    }

    addRating = async (rating: Rating): Promise<Result | undefined> => {
        return await this.ratingService.addRating({
            ...rating,
            restaurant: this.selectedRestaurant!
        });
    }

    getRatingsForItem = (itemId: string): Rating[] => {
        return this.ratings.filter(r => r.item.id === itemId);
    }

    private getReadonlyRatingsForItemAndRestaurant = (itemId: string, restaurantId: string, currentUserId: string): Rating[] => {
        return this.ratings.filter(r =>
            r.restaurant.id === restaurantId &&
            r.item.id === itemId &&
            r.user.id !== currentUserId
        );
    }

    get readonlyRatingsForSelectedItemAndRestaurant() {
        if (!this.selectedItem || !this.selectedRestaurant) {
            return [];
        }
        return this.getReadonlyRatingsForItemAndRestaurant(this.selectedItem!.id, this.selectedRestaurant!.id, this.ratingService.currentUser!.id);
    }

    private getMyRatingsForRestaurant = (restaurantId: string): Rating[] => {
        return this.myRatings.filter(r =>
            r.restaurant.id === restaurantId);
    }

    get myRatingsForSelectedRestaurant() {
        if (!this.selectedRestaurant) {
            return [];
        }
        const newRatings: Rating[] = this.items.map((item): Rating => {
            return {
                id: 'placeholder',
                restaurant: this.selectedRestaurant!,
                item,
                user: this.ratingService.currentUser!
            }
        });

        const myExistingRatings = this.getMyRatingsForRestaurant(this.selectedRestaurant!.id);
        return this.items.map((item): Rating => {
            return myExistingRatings.find(r => r.item.id === item.id) || newRatings.find(r => r.item.id === item.id)!;
        });
    }

    private getOverallRatingForItemAndRestaurant = (itemId: string, restaurantId: string): OverallRating | undefined => {
        return this.overallRatings.find(r => r.restaurant.id === restaurantId && r.item.id === itemId);
    }

    get overallRatingForSelectedItemAndRestaurant() {
        if (!this.selectedItem || !this.selectedRestaurant) {
            return undefined;
        }
        return this.getOverallRatingForItemAndRestaurant(this.selectedItem!.id, this.selectedRestaurant!.id);
    }

    getDistinctRestaurantsForItem = (itemId: string): Restaurant[] => {
        if (this.restaurants.length === 0) {
            return [];
        }
        const distinctRestaurantIds = [...new Set(this.getRatingsForItem(itemId).map(rating => rating.restaurant.id))];
        const restaurantsForItem: Restaurant[] = [];
        distinctRestaurantIds.forEach(id => {
            if (!restaurantsForItem.find(ri => ri.id === id)) {
                restaurantsForItem.push(this.restaurants.find(r => r.id === id)!);
            }
        });
        return restaurantsForItem
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    setSelectedRestaurant = (restaurant: Restaurant | undefined | null) => {
        if (this.selectedRestaurant !== restaurant && (this.selectedRestaurant?.id !== restaurant?.id || this.selectedRestaurant?.name !== restaurant?.name)) {
            this.selectedRestaurant = restaurant;
        }
    }

    setSelectedItem = (item: Item) => {
        this.selectedItem = item;
        localStorage.setItem('selectedItemId', item.id);
        this.appState.decrementWaitCounter();
    }

    private setMyRatings = (ratings: Rating[]) => {
        this.myRatings = ratings;
    }

    private setItems = (items: Item[]) => {
        this.items = items;
    }

    private setRatings = (ratings: Rating[]) => {
        this.ratings = ratings;
    }

    private setRestaurants = (restaurants: Restaurant[]) => {
        this.restaurants = restaurants;
        if (this.selectedRestaurant) {
            const existingRestaurant = restaurants.find(r => r.name === this.selectedRestaurant!.name);
            this.setSelectedRestaurant(existingRestaurant);
        }
    }

    get overallRatings() {
        if (this.ratings.length === 0) {
            return [];
        }
        const overallRatings: OverallRating[] = [];
        this.items.forEach(i => {
            this.restaurants.forEach(resto => {
                const itemRatings = this.ratings
                    .filter(r => r.item.id === i.id && r.rating && r.restaurant.id === resto.id)
                    .map(r => r.rating!);
                if (itemRatings.length > 0) {
                    const itemAverage = itemRatings.reduce((a, b) => a + b) / itemRatings.length;
                    overallRatings.push({
                        item: i,
                        restaurant: resto,
                        overallRating: itemAverage
                    });
                }
            })
        });
        overallRatings.sort((a, b) => b.overallRating - a.overallRating);
        return overallRatings;
    }
}
