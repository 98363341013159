import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { computed, makeAutoObservable } from 'mobx';
import { RatingService } from '../services';
import { Coordinate, newYork } from '../util';

export enum MobileOSType {
    Android = 'Android',
    IOS = 'iOS',
    Other = 'Other'
}

export const getMobileOS = (): MobileOSType => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return MobileOSType.Android;
    } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return MobileOSType.IOS;
    }
    return MobileOSType.Other;
}

export type Mode = 'dark' | 'light';

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#FFFFFF',
            //main: '#f50057',
            light: '#9390A1'
        },
        secondary: {
            main: '#3B3B98',
            dark: 'rgba(69, 19, 4)'
        },
        warning: {
            main: 'rgba(69, 19, 4)'
        },
        error: {
            main: '#f44336',
            dark: '#96281b'
        },
        background: {
            default: '#0a1124',
            paper: '#0a1124'
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#9390A1'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
};

const darkTheme = createTheme(darkThemeOptions);

const precision = 0.0001;

export class AppState {
    mobileType: MobileOSType = MobileOSType.Other;
    mode: Mode = 'dark';
    isMapReady = false;
    isMapShown = false;
    isUsingCurrentLocation = false;
    center: Coordinate | undefined = undefined;
    private waitCounter = 0;

    constructor(private ratingService: RatingService) {
        makeAutoObservable(this, {
            isLoading: computed,
            getCurrentLocation: false
        });
        this.getCurrentLocation();
        // To Update every 5 seconds
        // setInterval(() => this.getCurrentLocation(), 5000);
    }

    setMobileType = (type: MobileOSType) => {
        if (this.mobileType !== type) {
            this.mobileType = type;
        }
    }

    incrementWaitCounter = () => {
        this.waitCounter++;
    }

    decrementWaitCounter = () => {
        this.waitCounter--;
    }

    get isLoading(): boolean {
        return this.waitCounter > 0;
    }

    private setMode = (mode: Mode) => {
        if (this.mode !== mode) {
            this.mode = mode;
            localStorage.setItem('mode', mode);
        }
    }

    get theme(): Theme {
        return darkTheme;
    }

    setIsMapReady = (isReady: boolean) => {
        this.isMapReady = isReady;
    }

    setIsMapShown = (isMapShown: boolean) => {
        this.isMapShown = isMapShown;
    }

    setCenter = (center: Coordinate) => {
        this.center = center;
    }

    setIsUsingCurrentLocation = (isUsingCurrentLocation: boolean) => {
        this.isUsingCurrentLocation = isUsingCurrentLocation;
    }

    getCurrentLocation = async () => {
        this.incrementWaitCounter();
        setTimeout(() => {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: GeolocationPosition) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            if (this.center &&
                                Math.abs(this.center?.lat - pos.lat) <= precision &&
                                Math.abs(this.center?.lng - pos.lng) <= precision) {
                                return;
                            }
                            console.log('Current location changed');
                            this.setCenter(pos);
                            this.setIsUsingCurrentLocation(true);
                            // TEST changing locations
                            // if (!this.center) {
                            //     this.setCenter(pos);
                            // } else {
                            //     this.setCenter({ lat: this.center!.lat + 0.01, lng: this.center!.lng + 0.01 });
                            // }
                            // this.setIsUsingCurrentLocation(true);
                            // END TEST
                        }, (error: GeolocationPositionError) => {
                            console.log('Could not get current location');
                            console.log(error);
                            this.setIsUsingCurrentLocation(false);
                            this.setCenter(newYork);
                        },
                        {
                            enableHighAccuracy: true
                        }
                    )
                }
            } catch (err) {
                console.log('Unable to find current location, centering on New York city');
                this.setIsUsingCurrentLocation(false);
                this.setCenter(newYork);
            } finally {
                this.decrementWaitCounter();
            }
        }, 200);
    }
}
