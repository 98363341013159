import { Restaurant } from '../models';

export enum MobileOSType {
    Android = 'Android',
    IOS = 'iOS',
    Other = 'Other'
}

export const getMobileOS = (): MobileOSType => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return MobileOSType.Android;
    } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return MobileOSType.IOS;
    }
    return MobileOSType.Other;
}

export const manhattanLowercase = 'manhattan';

export interface Coordinate {
    lat: number;
    lng: number;
}

export const newYork: Coordinate = {
    lat: 40.75711,
    lng: -73.98278
};

export const getRestaurantFromPlace = (place: google.maps.places.PlaceResult): Restaurant => {
    const longitude = place.geometry!.location!.lng();
    const latitude = place.geometry!.location!.lat();
    const restaurant: Restaurant = {
        id: 'placeholder',
        name: place.name!,
        long: longitude,
        lat: latitude,
        searchResultTitle: place.name
    }
    return restaurant;
}

export const MEMOIZED_MAP = 'memoizedMap';
export const PLACES_SERVICE = 'placesService';

export const getMap = (): google.maps.Map | undefined => {
    if (window[MEMOIZED_MAP]) {
        return window[MEMOIZED_MAP] as google.maps.Map;
    } else {
        return undefined;
    }
}

export const getPlacesService = (): google.maps.places.PlacesService | undefined => {
    if (window[PLACES_SERVICE]) {
        return window[PLACES_SERVICE] as google.maps.places.PlacesService;
    } else {
        return undefined;
    }
}

