import { Rating, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { MobileOSType } from '../util';

type Size = 'small' | 'medium' | 'large';

interface ItemRatingProps {
    mobileType?: MobileOSType;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    title?: string;
    rating?: number | null;
    showRatingOnly?: boolean;
    handleRatingChange?: (newRating: number) => void;
    size?: Size
    showRatingValue?: boolean;
    isTitleBold?: boolean;
    name?: string;
}

export const ItemRating = observer((props: ItemRatingProps) => {
    const ref = useRef<HTMLElement>();

    const onTouchEnd = (e) => {
        const mouseClickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            clientX: e.changedTouches[0].clientX,
            screenX: e.changedTouches[0].screenX,
            movementX: e.changedTouches[0].movementX,
            clientY: e.changedTouches[0].clientY,
            screenY: e.changedTouches[0].screenY,
            movementY: e.changedTouches[0].movementY
        });
        ref.current?.dispatchEvent(mouseClickEvent);
    }

    const onTouchMove = (e) => {
        const mousemoveEvent = new MouseEvent('mousemove', {
            bubbles: true,
            cancelable: true,
            clientX: e.targetTouches[0].clientX,
            screenX: e.targetTouches[0].screenX,
            movementX: e.targetTouches[0].movementX,
            clientY: e.targetTouches[0].clientY,
            screenY: e.targetTouches[0].screenY,
            movementY: e.targetTouches[0].movementY
        });
        ref.current?.dispatchEvent(mousemoveEvent);
    }

    const handleRatingChange = (e, rating: number | null) => {
        if (props.handleRatingChange) {
            props.handleRatingChange(rating || 0);
        }
    };

    const getFontSize = (): string => {
        return props.mobileType
            ? props.mobileType === MobileOSType.Android
                ? '2.3rem'
                : props.mobileType === MobileOSType.IOS ? '2.9rem'
                    : '2.7rem'
            : '2.7rem'
    }

    return (
        <div className='flex-row' style={{ gap: '20px', height: '100%' }}>
            {!props.showRatingOnly && <Typography noWrap style={{ flex: '1', alignSelf: 'center', fontWeight: props.isTitleBold ? 'bold' : '' }}>{props.title}</Typography>}
            <div className='flex-row' style={{ alignSelf: 'center' }}>
                <Rating
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                    key={props.name || 'item-rating'}
                    ref={ref}
                    disabled={props.isDisabled}
                    readOnly={props.isReadOnly}
                    name={props.name || 'item-rating'}
                    value={props.rating}
                    onChange={handleRatingChange}
                    onChangeActive={handleRatingChange}
                    precision={props.isReadOnly ? 0.1 : 0.5}
                    size={props.size || 'large'}
                    style={{ justifyContent: 'center', flex: '1', alignSelf: 'center' }}
                    sx={props.size && props.size === 'large' ? { fontSize: getFontSize(), '& .MuiSvgIcon-root': { pointerEvents: 'none' } } : { '& .MuiSvgIcon-root': { pointerEvents: 'none' } }}
                />
            </div>
            {props.showRatingValue && props.rating && <Typography style={{ alignSelf: 'center' }}>{props.rating.toFixed(1)}</Typography>}
        </div >
    );
});
