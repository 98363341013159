import { Popover } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Restaurant, Result } from '../models';
import { AppState, RatingState, UserState } from '../state';
import { MobileOSType } from '../util';
import { RatingDetail } from './ratingDetail';
import { MapRatingMarkers } from './mapRatingMarkers';
import { MapPageBottom } from './mapPageBottom';

interface AddNewRatingsPageProps {
    onCancel: () => void;
    ratingState: RatingState;
    appState: AppState;
    userState: UserState;
    onPopupVisibilityChanged?: (isVisible: boolean) => void;
    handleSelectedRestaurant: (restaurant: Restaurant | null | undefined) => void;
    onHandleResult: (result: Result) => void;
    onIsLoading: (isLoading: boolean) => void;
}

export const AddNewRatingsPage = observer((props: AddNewRatingsPageProps) => {
    const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
    const [addRatingAnchorEl, setAddRatingAnchorEl] = useState<HTMLButtonElement | null>(null);
    const addRatingsRef = useRef(null);

    useEffect(() => {
        if (props.ratingState.selectedRestaurant && !addRatingAnchorEl) {
            handleAddRatingOpen();
        }
    }, [props.ratingState.selectedRestaurant]);

    const handleAddRatingOpen = () => {
        if (addRatingAnchorEl == null) {
            setAddRatingAnchorEl(addRatingsRef.current);
        }
    };

    const handleAddRatingClose = () => {
        setAddRatingAnchorEl(null);
        setIsManualEntry(false);
        props.ratingState.setSelectedRestaurant(undefined);
    };

    const handleSelectedRestaurant = (restaurant: Restaurant | null | undefined) => {
        props.handleSelectedRestaurant(restaurant);
        handleAddRatingOpen();
    }

    const handleRestaurantNameChange = (name: string) => {
        handleSelectedRestaurant({
            ...props.ratingState.selectedRestaurant!,
            name: name
        });
    }

    const handleAddManualRestaurant = () => {
        setIsManualEntry(true);
        handleSelectedRestaurant({
            id: 'placeholder',
            name: ''
        });
    }

    const isAddRatingOpen = Boolean(addRatingAnchorEl);
    const addRatingPopoverId = isAddRatingOpen ? 'add-rating-popover' : undefined;

    return (
        <>
            <div ref={addRatingsRef} id='add-ratings-div'>
                <Popover
                    id={addRatingPopoverId}
                    open={isAddRatingOpen}
                    anchorEl={addRatingAnchorEl}
                    onClose={handleAddRatingClose}
                    anchorReference={'none'}
                    anchorPosition={{ top: 140, left: 0 }}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    PaperProps={{
                        style: { width: '100%', maxWidth: props.appState.mobileType === MobileOSType.Other ? '600px' : 'calc(var(--app-width) - 36px' },
                    }}
                >
                    {props.ratingState.selectedRestaurant &&
                        <RatingDetail
                            appState={props.appState}
                            userState={props.userState}
                            ratingState={props.ratingState}
                            isManualEntry={isManualEntry}
                            onHandleClose={handleAddRatingClose}
                            onRestaurantNameChanged={handleRestaurantNameChange}
                            onHandleResult={props.onHandleResult}
                            onIsLoading={props.onIsLoading}
                        />
                    }
                </Popover>
            </div>
            <MapRatingMarkers
                isMapReady={props.appState.isMapReady}
                onRestaurantSelected={handleSelectedRestaurant}
                overallRatings={props.ratingState.overallRatings}
            />
            <MapPageBottom
                userState={props.userState}
                onMapCancel={props.onCancel}
                onAddManual={handleAddManualRestaurant} />
        </>
    );
});
