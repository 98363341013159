import { makeAutoObservable } from 'mobx';
import { Subject, takeUntil } from 'rxjs';
import { AllowedUser, Result, User } from '../models';
import { UserService } from '../services';
import { AppState } from './appState';

export class UserState {
    currentUser: User | null | undefined = undefined;
    currentToken: any | null | undefined = undefined;
    errorMessage: string | undefined = undefined;
    allowedUsers: AllowedUser[] = [];
    isUserAdmin: boolean | undefined = undefined;

    constructor(private appState: AppState, private userService: UserService, private stop$: Subject<boolean>) {
        makeAutoObservable(this, {
        });
        this.appState.incrementWaitCounter();

        this.userService.currentToken$.pipe(
            takeUntil(this.stop$)
        ).subscribe(token => {
            if (!token) {
                this.appState.decrementWaitCounter();
            }
            this.setCurrentToken(token);
        });

        this.userService.currentUser$.pipe(
            takeUntil(this.stop$)
        ).subscribe(user => {
            this.setCurrentUser(user);
        });

        this.userService.errors$.pipe(
            takeUntil(this.stop$)
        ).subscribe(errorMessage => {
            this.setCurrentErrorMessage(errorMessage);
        });

        this.userService.allowedUsers$.pipe(
            takeUntil(this.stop$)
        ).subscribe(this.setAllowedUsers);

        this.userService.isUserAdmin$.pipe(
            takeUntil(this.stop$)
        ).subscribe(this.setIsUserAdmin);
    }

    logout = () => {
        this.userService.logout();
    }

    login = () => {
        this.appState.incrementWaitCounter();
        this.userService.login();
    }

    addAllowedUser = async (userEmail: string): Promise<Result | undefined> => {
        return await this.userService.addAllowedUser(userEmail);
    }

    updateAllowedUser = async (user: AllowedUser): Promise<Result | undefined> => {
        return await this.userService.updateAllowedUser(user);
    }

    deleteAllowedUser = async (user: AllowedUser): Promise<Result | undefined> => {
        return await this.userService.deleteAllowedUser(user);
    }

    private setAllowedUsers = (allowedUsers: AllowedUser[]) => {
        this.allowedUsers = allowedUsers;
    }

    private setCurrentUser = (user: User | null | undefined) => {
        this.currentUser = user;
    }

    private setCurrentToken = (token: any | null | undefined) => {
        this.currentToken = token;
    }
    private setCurrentErrorMessage = (errorMessage: string | undefined) => {
        this.errorMessage = errorMessage;
    }

    private setIsUserAdmin = (isAdmin: boolean) => {
        this.isUserAdmin = isAdmin;
    }
}
