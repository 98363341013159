import { CircularProgress, Backdrop } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { MainPage } from './mainPage';
import { AppState, RatingState, UserState } from '../state';
import { MobileOSType } from '../state/appState';
import { Restaurant, Result } from '../models';
import '../Styles.scss';
import { LoginPage } from './loginPage';

interface HomeComponentProps {
    appState: AppState;
    userState: UserState;
    ratingState: RatingState;
    handleSelectedRestaurant: (restaurant: Restaurant | null | undefined) => void;
    onShowMap: (showMap: boolean) => void;
    onHandleResult: (result: Result) => void;
    onIsLoading: (isLoading: boolean) => void;
}

export const HomeComponent = observer((props: HomeComponentProps) => {
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

    const handleLogin = () => {
        props.userState.login();
    }

    const handleOnPopupVisibilityChanged = (isVisible: boolean) => {
        setIsPopupVisible(isVisible);
    }

    const handleOnShowMap = (showMap: boolean) => {
        props.onShowMap(showMap);
    }

    const shouldShowSpinner = () => {
        return props.appState.isLoading || !!(props.userState.currentUser && !props.appState.isMapReady);
    }

    return (
        <div
            className='flex-column'
            style={{
                display: 'flex',
                flexDirection: 'column',
                //height: (props.appState.isLoading || !props.appState.isMapReady) ? 'var(--app-height)' : isMapVisible ? '0px' : 'var(--app-height)',
                height: props.appState.isMapShown ? '0px' : 'var(--app-height)',
                backgroundImage: `url(${process.env.PUBLIC_URL}/Manhattan2.jpg`,
                backgroundRepeat: props.appState.mobileType === MobileOSType.Other ? 'no-repeat' : 'repeat',
                backgroundPosition: props.appState.mobileType === MobileOSType.Other ? 'top' : '0% 0%',
                filter: isPopupVisible ? 'blur(2px)' : '',
                WebkitFilter: isPopupVisible ? 'blur(2px)' : '',
                opacity: isPopupVisible ? '0.5' : '1',
                backgroundColor: isPopupVisible ? 'black' : 'transparent',
            }}>
            {shouldShowSpinner() ?
                <Backdrop
                    sx={{ zIndex: 10000 }}
                    open={shouldShowSpinner()}
                >
                    <CircularProgress thickness={6} size={'100px'} />
                </Backdrop>
                :
                <>
                    {props.userState.currentUser
                        ?
                        <MainPage
                            onPopupVisibilityChanged={handleOnPopupVisibilityChanged}
                            handleSelectedRestaurant={props.handleSelectedRestaurant}
                            onShowMap={handleOnShowMap}
                            appState={props.appState}
                            ratingState={props.ratingState}
                            onHandleResult={props.onHandleResult}
                            onIsLoading={props.onIsLoading}
                            userState={props.userState} />
                        :
                        <LoginPage userState={props.userState} />
                    }
                </>
            }
        </div >
    );
});
