import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import * as GraphQLAPI from '../API';
import { AllowedUser, User, Restaurant, Rating, Item } from '../models';

export interface CreateUserQueryResult {
    data: {
        createUser: User | null
    }
}

export interface UpdateUserQueryResult {
    data: {
        updateUser: User | null
    }
}

export interface ListUsersQueryResult {
    data: {
        listUsers: {
            items: User[] | null
        }
    }
}

export interface CreateAllowedUserQueryResult {
    data: {
        createAllowedUser: AllowedUser | null
    }
}

export interface UpdateAllowedUserQueryResult {
    data: {
        updateAllowedUser: AllowedUser | null
    }
}

export interface DeleteAllowedUserQueryResult {
    data: {
        deleteAllowedUser: AllowedUser | null
    }
}

export interface GraphQLError {
    errorType: string;
    message: string;
}

export interface ListAllowedUsersQueryResult {
    data: {
        listAllowedUsers: {
            items: AllowedUser[] | null
        }
    },
    errors: GraphQLError[];
}

export interface ListRestaurantsQueryResult {
    data: {
        listRestaurants: {
            items: Restaurant[] | null
        }
    }
}

export interface CreateRestaurantQueryResult {
    data: {
        createRestaurant: Restaurant | null
    }
}

export interface UpdateRestaurantQueryResult {
    data: {
        updateRestaurant: Restaurant | null
    }
}

export interface ListRatingsQueryResult {
    data: {
        listRatings: {
            items: Rating[] | null
        }
    }
}

export interface CreateRatingQueryResult {
    data: {
        createRating: Rating | null
    }
}

export interface UpdateRatingQueryResult {
    data: {
        updateRating: Rating | null
    }
}

export interface DeleteRatingQueryResult {
    data: {
        deleteRating: Rating | null
    }
}

export interface ListItemsQueryResult {
    data: {
        listItems: {
            items: Item[] | null
        }
    }
}

export interface CreateItemQueryResult {
    data: {
        createItem: Item | null
    }
}

export const findUser = async (email: string): Promise<User | undefined> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listUsers, {
            filter: {
                email: {
                    eq: email
                }
            }
        })
    ) as unknown as Promise<ListUsersQueryResult>);
    const items = res.data.listUsers.items;
    if (items && items.length === 1) {
        return items[0];
    } else if (items && items.length > 1) {
        throw (`More than 1 user found with email: ` + email);
    } else {
        return undefined;
    }
}

export const getAllUsers = async (): Promise<User[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listUsers)) as unknown as Promise<ListUsersQueryResult>);
    return res.data.listUsers.items || [];
}

export const createUser = async (user: GraphQLAPI.CreateUserInput): Promise<User | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createUser, {
            input: {
                email: user.email,
                name: user.name
            }
        })
    ) as unknown as Promise<CreateUserQueryResult>);
    return res.data.createUser;
}

export const updateUser = async (user: GraphQLAPI.UpdateUserInput): Promise<User | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.updateUser, {
            input: {
                id: user.id,
                email: user.email,
                name: user.name,
                avatar: user.avatar
            }
        })
    ) as unknown as Promise<UpdateUserQueryResult>);
    return res.data.updateUser;
}

export const getAllowedUsers = async (): Promise<AllowedUser[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listAllowedUsers)) as unknown as Promise<ListAllowedUsersQueryResult>);
    return res.data.listAllowedUsers.items || [];
}

export const createAllowedUser = async (user: GraphQLAPI.CreateAllowedUserInput): Promise<AllowedUser | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createAllowedUser, {
            input: {
                email: user.email
            }
        })
    ) as unknown as Promise<CreateAllowedUserQueryResult>);
    return res.data.createAllowedUser;
}

export const updateAllowedUser = async (allowedUser: GraphQLAPI.UpdateAllowedUserInput): Promise<AllowedUser | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.updateAllowedUser, {
            input: {
                id: allowedUser.id,
                email: allowedUser.email
            }
        })
    ) as unknown as Promise<UpdateAllowedUserQueryResult>);
    return res.data.updateAllowedUser;
}

export const deleteAllowedUser = async (allowedUser: GraphQLAPI.DeleteAllowedUserInput): Promise<AllowedUser | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.deleteAllowedUser, {
            input: {
                id: allowedUser.id
            }
        })
    ) as unknown as Promise<DeleteAllowedUserQueryResult>);
    return res.data.deleteAllowedUser;
}

export const findRestaurant = async (name: string): Promise<Restaurant | undefined> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listRestaurants, {
            filter: {
                name: {
                    eq: name
                }
            }
        })
    ) as unknown as Promise<ListRestaurantsQueryResult>);
    const items = res.data.listRestaurants.items;
    if (items && items.length === 1) {
        return items[0];
    } else if (items && items.length > 1) {
        throw (`More than 1 restaurant found with name: ` + name);
    } else {
        return undefined;
    }
}

export const getAllRestaurants = async (): Promise<Restaurant[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listRestaurants)) as unknown as Promise<ListRestaurantsQueryResult>);
    return res.data.listRestaurants.items || [];
}

export const createRestaurant = async (restaurant: GraphQLAPI.CreateRestaurantInput): Promise<Restaurant | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createRestaurant, {
            input: {
                name: restaurant.name,
                lat: restaurant.lat,
                long: restaurant.long
            }
        })
    ) as unknown as Promise<CreateRestaurantQueryResult>);
    return res.data.createRestaurant;
}

export const updateRestaurant = async (restaurant: GraphQLAPI.UpdateRestaurantInput): Promise<Restaurant | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.updateRestaurant, {
            input: {
                id: restaurant.id,
                searchResultTitle: restaurant.name,
                name: restaurant.name,
                lat: restaurant.lat,
                long: restaurant.long
            }
        })
    ) as unknown as Promise<CreateRestaurantQueryResult>);
    return res.data.createRestaurant;
}

export const getAllItems = async (): Promise<Item[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listItems)) as unknown as Promise<ListItemsQueryResult>);
    return res.data.listItems.items || [];
}

export const findItem = async (name: string): Promise<Item | undefined> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listItems, {
            filter: {
                name: {
                    eq: name
                }
            }
        })
    ) as unknown as Promise<ListItemsQueryResult>);
    const items = res.data.listItems.items;
    if (items && items.length === 1) {
        return items[0];
    } else if (items && items.length > 1) {
        throw (`More than 1 item found with name: ` + name);
    } else {
        return undefined;
    }
}

export const createItem = async (item: GraphQLAPI.CreateItemInput): Promise<Item | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createItem, {
            input: {
                name: item.name,
            }
        })
    ) as unknown as Promise<CreateItemQueryResult>);
    return res.data.createItem;
}

export const getAllRatings = async (): Promise<Rating[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listRatings)) as unknown as Promise<ListRatingsQueryResult>);
    return res.data.listRatings.items || [];
}

export const getMyRatings = async (userId: string): Promise<Rating[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listRatings, {
        filter: {
            userId: {
                eq: userId
            }
        }
    })
    ) as unknown as Promise<ListRatingsQueryResult>);
    return res.data.listRatings.items || [];
}

export const findRating = async (userId: string, restaurantId: string, itemId: string): Promise<Rating | undefined> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listRatings, {
            filter: {
                userId: {
                    eq: userId
                },
                restaurantId: {
                    eq: restaurantId
                },
                itemId: {
                    eq: itemId
                }
            }
        })
    ) as unknown as Promise<ListRatingsQueryResult>);
    const items = res.data.listRatings.items;
    if (items && items.length === 1) {
        return items[0];
    } else if (items && items.length > 1) {
        throw (`More than 1 rating found for user: ${userId}, restaurant: ${restaurantId}, item:${itemId}`);
    } else {
        return undefined;
    }
}

export const createRating = async (rating: GraphQLAPI.CreateRatingInput): Promise<Rating | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createRating, {
            input: {
                rating: rating.rating,
                itemId: rating.itemId,
                restaurantId: rating.restaurantId,
                userId: rating.userId,
                notes: rating.notes
            }
        })
    ) as unknown as Promise<CreateRatingQueryResult>);
    return res.data.createRating;
}

export const updateRating = async (rating: GraphQLAPI.UpdateRatingInput): Promise<Rating | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.updateRating, {
            input: {
                id: rating.id,
                rating: rating.rating,
                itemId: rating.itemId,
                restaurantId: rating.restaurantId,
                userId: rating.userId,
                notes: rating.notes
            }
        })
    ) as unknown as Promise<UpdateRatingQueryResult>);
    return res.data.updateRating;
}

export const deleteRating = async (rating: GraphQLAPI.DeleteRatingInput): Promise<Rating | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.deleteRating, {
            input: {
                id: rating.id
            }
        })
    ) as unknown as Promise<DeleteRatingQueryResult>);
    return res.data.deleteRating;
}

