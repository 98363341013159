import { AccountCircleOutlined, Logout } from '@mui/icons-material';
import { Fab, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { useState } from 'react';
import { UserState } from '../state';

interface LogoutButtonProps {
    userState: UserState;
}

export const LogoutButton = (props: LogoutButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        props.userState.logout();
    }
    return (
        <>
            <Fab size='small' color='secondary' aria-label='Profile button to log out'
                sx={{ backgroundColor: 'rgba(69, 19, 4)' }}
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
            >
                <AccountCircleOutlined />
            </Fab>
            <Menu
                id='logout-menu'
                aria-labelledby='logout-menu-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize='small' />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu >
        </>
    );
}
