/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        avatar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRestaurant = /* GraphQL */ `
  query GetRestaurant($id: ID!) {
    getRestaurant(id: $id) {
      id
      name
      searchResultTitle
      lat
      long
      createdAt
      updatedAt
    }
  }
`;
export const listRestaurants = /* GraphQL */ `
  query ListRestaurants(
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestaurants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchResultTitle
        lat
        long
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      userId
      user {
        id
        email
        name
        avatar
        createdAt
        updatedAt
      }
      restaurantId
      restaurant {
        id
        name
        searchResultTitle
        lat
        long
        createdAt
        updatedAt
      }
      itemId
      item {
        id
        name
        createdAt
        updatedAt
      }
      rating
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          avatar
          createdAt
          updatedAt
        }
        restaurantId
        restaurant {
          id
          name
          searchResultTitle
          lat
          long
          createdAt
          updatedAt
        }
        itemId
        item {
          id
          name
          createdAt
          updatedAt
        }
        rating
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAllowedUser = /* GraphQL */ `
  query GetAllowedUser($id: ID!) {
    getAllowedUser(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listAllowedUsers = /* GraphQL */ `
  query ListAllowedUsers(
    $filter: ModelAllowedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllowedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
