import { Paper, Typography } from '@mui/material';
import { OverallRating } from '../models';
import { RatingState } from '../state';

import '../Styles.scss';
import { ItemRating } from './itemRating';

interface RatingListItemProps {
    rating: OverallRating;
    ratingState: RatingState;
}

export const RatingListItem = (props: RatingListItemProps) => {
    return (
        // <Paper style={{ backgroundColor: 'rgba(16, 15, 31, 0.7)' }}>
        <Paper style={{ backgroundColor: 'rgba(23, 3, 1, 0.7)' }}>
            <div className='flex-row' style={{ padding: '4px 16px' }}>
                <div style={{ flex: 2 }}>
                    <Typography>{props.rating.restaurant.name}</Typography>
                </div>
                <div style={{ flex: 1, height: '100%' }}>
                    <ItemRating
                        key={`rating-${props.rating.item.id}`}
                        isReadOnly={true}
                        title={props.rating.item.name}
                        rating={props.rating.overallRating}
                        showRatingOnly={true}
                        showRatingValue={true}
                        size='medium'
                    />
                </div>
            </div>
        </Paper>
    );
}