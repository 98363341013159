import { Add, AddLocationAltOutlined, AdminPanelSettingsOutlined } from '@mui/icons-material';
import { Button, Fab, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { OverallRating, Restaurant, Result } from '../models';
import { AppState, RatingState, UserState } from '../state';
import '../Styles.scss';
import { MobileOSType } from '../util';
import { AddNewRatingsPage } from './addNewRatingPage';
import { AdminPage } from './adminPage';
import { LogoutButton } from './logoutButton';
import { RatingsList } from './ratingsList';
import { RestaurantSearchBar } from './restaurantSearchBar';

interface MainPageProps {
    appState: AppState;
    ratingState: RatingState;
    userState: UserState;
    onPopupVisibilityChanged: (isVisible: boolean) => void;
    handleSelectedRestaurant: (restaurant: Restaurant | null | undefined) => void;
    onShowMap: (showMap: boolean) => void;
    onHandleResult: (result: Result) => void;
    onIsLoading: (isLoading: boolean) => void;
}

export const MainPage = observer((props: MainPageProps) => {
    const [isRestaurantAddOpen, setIsRestaurantAddOpen] = useState<boolean>(true);
    const [isAdminOpen, setIsAdminOpen] = useState<boolean>(true);
    const [selectedRestaurantFromSearch, setSelectedRestaurantFromSearch] = useState<Restaurant | null>(null);

    const handleOpenAdmin = () => {
        setIsAdminOpen(true);
    }

    const handleCloseAdmin = () => {
        setIsAdminOpen(false);
    }

    const handleChangeItem = (event) => {
        const selectedItemId = event.target.value;
        const foundItem = props.ratingState.items.find(i => i.id === selectedItemId)!;
        props.ratingState.setSelectedItem(foundItem);
    }

    const handleRestaurantAddClick = () => {
        setIsRestaurantAddOpen(true);
        props.onShowMap(true);
        props.ratingState.setSelectedRestaurant(null);
    }

    const handleRestaurantAddCancel = () => {
        setIsRestaurantAddOpen(false);
        handleCloseAdmin();
        props.onShowMap(false);
    }

    const handleSelectedRestaurantChange = (e, restaurant: Restaurant | null) => {
        props.ratingState.setSelectedRestaurant(restaurant);
        setSelectedRestaurantFromSearch(restaurant);
    };

    const getOverallRatings = (): OverallRating[] => {
        if (selectedRestaurantFromSearch) {
            return props.ratingState.overallRatings.filter(r => r.restaurant.id === selectedRestaurantFromSearch.id)
                .filter(r => r.item.id === props.ratingState.selectedItem?.id);
        } else {
            return props.ratingState.overallRatings
                .filter(r => r.item.id === props.ratingState.selectedItem?.id);
        }
    }

    return (
        <>
            {isRestaurantAddOpen ?
                <AddNewRatingsPage
                    appState={props.appState}
                    ratingState={props.ratingState}
                    userState={props.userState}
                    handleSelectedRestaurant={props.handleSelectedRestaurant}
                    onPopupVisibilityChanged={props.onPopupVisibilityChanged}
                    onHandleResult={props.onHandleResult}
                    onIsLoading={props.onIsLoading}
                    onCancel={handleRestaurantAddCancel} />
                : props.ratingState.selectedItem &&
                <div id='main-page-div' style={{
                    height: 'calc(var(--app-height))'
                }}>
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        height: 'calc(var(--app-height))', position: 'fixed', top: '0', padding: '10px 0px',
                        left: props.appState.mobileType === MobileOSType.Other ? 'calc(calc(var(--app-width) - 640px)/2)' : '0',
                        maxWidth: props.appState.mobileType === MobileOSType.Other ? '640px' : '100%',
                        width: '100%'
                    }}>
                        {isAdminOpen ?
                            <AdminPage appState={props.appState} userState={props.userState} onHomeClicked={handleCloseAdmin} />
                            : <>
                                <Typography
                                    fontWeight={'900'}
                                    letterSpacing={'0.16em'}
                                    fontSize={'1.6em'}
                                    fontFamily={'Italiana'}
                                    textAlign={'center'}
                                    textTransform={'capitalize'}
                                    style={{ marginTop: '20px' }}>
                                    {props.ratingState.selectedItem ? `Best ${props.ratingState.selectedItem?.name}` : `\u2002`}
                                </Typography >
                                <div className='flex-row' style={{ maxHeight: `var(--app-height)`, height: '100%' }}>
                                    <div className='flex-column' style={{ width: '100%' }}>
                                        <div className='flex-row' style={{ padding: '10px', width: '100%' }}>
                                            <FormControl variant='standard' sx={{ m: 1, minWidth: 120, justifyContent: 'center' }}>
                                                <Select
                                                    labelId='select-item-label'
                                                    id='select-item'
                                                    value={props.ratingState.selectedItem?.id || ''}
                                                    onChange={handleChangeItem}>
                                                    {
                                                        props.ratingState.items.map(item => <MenuItem key={`select-item-${item.name}`} value={item.id}>{item.name}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                            <div style={{ flex: '10' }}>
                                                <RestaurantSearchBar
                                                    id='search-restaurants'
                                                    items={props.ratingState.selectedItem && props.ratingState.restaurants.length > 0 ? props.ratingState.getDistinctRestaurantsForItem(props.ratingState.selectedItem!.id) : []}
                                                    placeholder='Search'
                                                    handleChange={handleSelectedRestaurantChange}
                                                />
                                            </div>
                                        </div>
                                        <RatingsList
                                            overallRatings={getOverallRatings()}
                                            ratingState={props.ratingState}
                                            userState={props.userState}
                                            appState={props.appState}
                                            onPopupVisibilityChanged={props.onPopupVisibilityChanged}
                                            onHandleResult={props.onHandleResult}
                                            onIsLoading={props.onIsLoading}
                                        />
                                    </div>
                                    <div className='flex-row' style={{
                                        position: 'fixed', bottom: '16px', padding: '0px 16px', width: '100%',
                                        maxWidth: props.appState.mobileType === MobileOSType.Other ? '640px' : '100%'
                                    }}>
                                        {/* <Fab size='small' color='secondary' aria-label="add" onClick={handleRestaurantAddClick}
                                    sx={{ backgroundColor: 'rgba(69, 19, 4)' }}>
                                    <Add />
                                </Fab> */}
                                        <Button onClick={handleRestaurantAddClick} aria-label='show map to add new ranking' color={'warning'} variant={'contained'} startIcon={<AddLocationAltOutlined />}
                                        >Map</Button>

                                        {props.userState.currentToken &&
                                            <div className='flex-row' style={{ alignSelf: 'flex-end', flex: '1', justifyContent: 'flex-end' }}>
                                                {props.userState.isUserAdmin &&
                                                    <Fab size='small' color='secondary' aria-label='admin page button'
                                                        sx={{ backgroundColor: 'rgba(69, 19, 4)' }}
                                                        onClick={handleOpenAdmin}>
                                                        <AdminPanelSettingsOutlined />
                                                    </Fab>
                                                }
                                                <LogoutButton userState={props.userState} />
                                            </div>
                                        }
                                    </div>
                                </div >
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
});
