import { Search } from '@mui/icons-material';
import { Autocomplete, AutocompleteRenderInputParams, InputAdornment, TextField, Typography } from '@mui/material';

import '../Styles.scss';

interface RestaurantSearchBarProps {
    id: string,
    items: any[];
    placeholder: string;
    handleChange: (event, value) => void;
    onClick?: () => void;
    onClose?: () => void;
    selectedItems?: any;
}

export const RestaurantSearchBar = (props: RestaurantSearchBarProps) => {
    const isOptionEqualToValue = (option, value) => {
        return option?.id?.toString() === value?.id?.toString();
    }
    return (
        <Autocomplete
            onBlur={props.onClose}
            onClick={props.onClick}
            onClose={props.onClose}
            freeSolo={true}
            id={props.id}
            limitTags={8}
            options={props.items}
            getOptionLabel={(item) => item?.name || ''}
            onChange={props.handleChange}
            value={props.selectedItems}
            isOptionEqualToValue={isOptionEqualToValue}
            openOnFocus={false}
            renderOption={(listProps, item) => (
                <li {...listProps}>
                    <Typography>{item.name}</Typography>
                </li>
            )}
            renderInput={(renderInputParams: AutocompleteRenderInputParams) => (
                <div ref={renderInputParams.InputProps.ref}
                    className='flex-row'
                    style={{
                        alignItems: 'center', width: '100%'
                    }}>
                    <TextField style={{ flex: 1 }} InputProps={{
                        ...renderInputParams.InputProps, startAdornment: (<InputAdornment position="start"> <Search /> </InputAdornment>),
                    }}
                        placeholder={props.placeholder}
                        inputProps={{
                            ...renderInputParams.inputProps
                        }}
                        InputLabelProps={{ style: { display: 'none' } }}
                    />
                </div >
            )}
        />
    );
}
