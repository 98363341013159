import { Observable, ReplaySubject, Subject, Subscription, take, takeUntil } from 'rxjs';
import * as subscriptions from '../graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import { Item, Rating, Restaurant, Result, User } from '../models';
import { createRating, createRestaurant, findRating, findRestaurant, getAllItems, getAllRatings, getAllRestaurants, getMyRatings, updateRating, deleteRating } from './graphQLOperations';
import { UserService } from './userService';

export class RatingService {
    private allRatingsSubject$ = new ReplaySubject<Rating[]>();
    private myRatingsSubject$ = new ReplaySubject<Rating[]>();
    private allItemsSubject$ = new ReplaySubject<Item[]>();
    private allRestaurantsSubject$ = new ReplaySubject<Restaurant[]>();
    private changesSubscriptions$: Subscription[] = [];
    currentUser: User | undefined | null = undefined;

    constructor(private userService: UserService, private stop$: Subject<boolean>) {
        this.userService.currentUser$.pipe(
            takeUntil(stop$))
            .subscribe(user => {
                this.currentUser = user;
                this.fetchCollections();
            });

        this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onCreateRating)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleRatingChange(provider, value),
                error: (error) => console.warn(error)
            }));
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateRating)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleRatingChange(provider, value),
                error: (error) => console.warn(error)
            }));
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteRating)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleRatingChange(provider, value),
                error: (error) => console.warn(error)
            }));

        this.stop$.pipe(
            take(1)
        ).subscribe(a => {
            this.changesSubscriptions$.forEach(s => s.unsubscribe());
        });
    }

    private handleRatingChange(provider: any, value: any) {
        console.log('Ratings changed, server is sending new values...');
        this.fetchCollections();
    }

    get allRatings$(): Observable<Rating[]> {
        return this.allRatingsSubject$.pipe(
            takeUntil(this.stop$)
        );
    };

    get myRatings$(): Observable<Rating[]> {
        return this.myRatingsSubject$.pipe(
            takeUntil(this.stop$)
        );
    };

    get allRestaurants$(): Observable<Restaurant[]> {
        return this.allRestaurantsSubject$.pipe(
            takeUntil(this.stop$)
        );
    };

    get allItems$(): Observable<Item[]> {
        return this.allItemsSubject$.pipe(
            takeUntil(this.stop$)
        );
    };

    private fetchCollections = async () => {
        try {
            const allRatings = await getAllRatings();
            this.allRatingsSubject$.next(allRatings);
            const allRestaurants = await getAllRestaurants();
            this.allRestaurantsSubject$.next(allRestaurants);
            const allItems = await getAllItems();
            this.allItemsSubject$.next(allItems);
            const myRatings = await getMyRatings(this.currentUser!.id);
            this.myRatingsSubject$.next(myRatings);

            // To check orphaned ratings
            // Also update the listRatings to exclude GraphQL from returning the Restaurant object
            // const restoIds = allRestaurants.map(r => r.id);
            // const ratingRestoIds = allRatings.map(r => r.restaurantId!);

            // ratingRestoIds.forEach(ratingRestoId => {
            //     if (!restoIds.includes(ratingRestoId)) {
            //         console.log('Missing resto id: ' + ratingRestoId);
            //         const ratings = allRatings.filter(r => r.restaurantId === ratingRestoId).map(r => r.id);
            //         console.log(ratings);
            //     }
            // });

        } catch (err) {
            console.log(err);
        }
    }

    addRating = async (newRating: Rating): Promise<Result | undefined> => {
        try {
            let foundRestaurant: Restaurant | null | undefined = await findRestaurant(newRating.restaurant.name);
            if (!foundRestaurant) {
                foundRestaurant = await createRestaurant(newRating.restaurant);
            }
            let foundRating: Rating | null | undefined = await findRating(newRating.user.id, foundRestaurant!.id, newRating.item.id);
            if (foundRating) {
                if (!newRating.rating || newRating.rating === 0) {
                    const deletedRating = await deleteRating({
                        id: foundRating.id
                    });
                    return deletedRating ? { status: 'success', message: 'Rating saved' } : { status: 'error', message: 'Could not save rating' };
                } else {
                    const updatedRating = await updateRating(
                        {
                            id: foundRating.id,
                            userId: this.currentUser!.id,
                            restaurantId: foundRestaurant!.id,
                            itemId: foundRating.item.id,
                            rating: newRating.rating,
                            notes: newRating.notes
                        }
                    );
                    return updatedRating ? { status: 'success', message: 'Rating saved' } : { status: 'error', message: 'Could not save rating' };
                }
            } else {
                if (newRating.rating && newRating.rating > 0) {
                    const createdRating = await createRating(
                        {
                            userId: this.currentUser!.id,
                            restaurantId: foundRestaurant!.id,
                            itemId: newRating.item.id,
                            rating: newRating.rating,
                            notes: newRating.notes
                        }
                    );
                    return createdRating ? { status: 'success', message: 'Rating saved' } : { status: 'error', message: 'Could not save rating' };
                } else {
                    return undefined;
                }
            }
        } catch (e) {
            console.error(e);
            return { status: 'error', message: `Could not save rating` };
        }
    }
}
