import { memo, useEffect } from 'react';
import { OverallRating, Restaurant } from '../models';
import '../Styles.scss';

interface MapRatingMarkersProps {
    isMapReady: boolean;
    onRestaurantSelected?: (restaurant: Restaurant) => void;
    overallRatings?: OverallRating[];
}

const propsAreEqual = (prevProps: MapRatingMarkersProps, nextProps: MapRatingMarkersProps): boolean => {
    if (prevProps.onRestaurantSelected?.length !== nextProps.onRestaurantSelected?.length) {
        return false;
    }
    if (prevProps.overallRatings && !nextProps.overallRatings) {
        return false;
    }
    if (nextProps.overallRatings && !prevProps.overallRatings) {
        return false;
    }
    if (!nextProps.overallRatings && !prevProps.overallRatings) {
        return true;
    }
    if (prevProps.isMapReady !== nextProps.isMapReady) {
        return false;
    }

    const prevRatingsItemIds = prevProps.overallRatings ? prevProps.overallRatings.map(r => r.item.id) : [];
    const nextRatingsItemIds = nextProps.overallRatings ? nextProps.overallRatings.map(r => r.item.id) : [];
    const areItemsSame = prevRatingsItemIds.every(prevItemId => nextRatingsItemIds?.includes(prevItemId))
        && nextRatingsItemIds.every(nextItemId => prevRatingsItemIds?.includes(nextItemId));
    if (!areItemsSame) {
        return false;
    }

    // Only compare restaurants that have lat and long (those that weren't manually entered)
    // since those are the only ones that should be drawn on the map.
    // There is no need to redraw the map if restaurants without coordinates were added.
    const prevRatingsRestoIds = prevProps.overallRatings ? prevProps.overallRatings
        .filter(r => !!r.restaurant.lat && !!r.restaurant.long)
        .map(r => r.restaurant.id) : [];
    const nextRatingsRestoIds = nextProps.overallRatings ? nextProps.overallRatings
        .filter(r => !!r.restaurant.lat && !!r.restaurant.long)
        .map(r => r.restaurant.id) : [];
    const areRestosSame = prevRatingsRestoIds.every(prevRestoId => nextRatingsRestoIds?.includes(prevRestoId))
        && nextRatingsRestoIds.every(nextRestoId => prevRatingsRestoIds?.includes(nextRestoId));
    if (!areRestosSame) {
        return false;
    }

    return true;
}

export const MapRatingMarkers = memo((props: MapRatingMarkersProps) => {
    const initializeMarkers = async () => {
        const existingMap = window['memoizedMap'];
        if (!existingMap) {
            return;
        }
        if (!props.isMapReady) {
            return;
        }
        console.log('Adding markers');
        const existingMarkers = window['mapMarkers'];
        if (existingMarkers) {
            (existingMarkers as google.maps.Marker[]).forEach(marker => {
                google.maps.event.clearInstanceListeners(marker);
                marker.setMap(null);
            });
        }
        if (props.overallRatings) {
            const newMarkers: any[] = [];
            props.overallRatings
                .filter(or => or.restaurant.lat && or.restaurant.long)
                .forEach(or => {
                    const marker = new google.maps.Marker({
                        position: { lat: or.restaurant.lat!, lng: or.restaurant.long! },
                        map: existingMap,
                    });
                    if (props.onRestaurantSelected) {
                        marker.addListener('click', ({ domEvent, latLng }) => {
                            props.onRestaurantSelected!(or.restaurant);
                        });
                    }
                    newMarkers.push(marker);
                });
            window['mapMarkers'] = newMarkers;
        }
    }

    useEffect(() => {
        initializeMarkers();
    }, [props.overallRatings, props.isMapReady]);

    return (
        <></>
    );
}, propsAreEqual);
