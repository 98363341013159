import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { Subject } from 'rxjs';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { RatingService, UserService } from './services';
import { AppState, RatingState, UserState } from './state';
import { getMobileOS } from './util';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/italiana/400.css';
import { Amplify, Auth } from 'aws-amplify';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import config from './aws-exports';

const currentUri = window.location.origin + window.location.pathname;

const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: currentUri,
    redirectSignOut: currentUri
  },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => {
    const currentSession = await Auth.currentSession();
    return { Authorization: currentSession.getIdToken().getJwtToken() };
  }
}
Amplify.configure(updatedAwsConfig);

const stop$ = new Subject<boolean>();

const userService = new UserService(stop$);
const ratingService = new RatingService(userService, stop$);
const appState = new AppState(ratingService);
const userState = new UserState(appState, userService, stop$);
const ratingState = new RatingState(ratingService, appState, stop$);

const mobileType = getMobileOS();
console.log(`Mobile type: ` + mobileType.toString());
appState.setMobileType(mobileType);

// load the Google SDK
const script = document.createElement('script');
script.src = 'https://apis.google.com/js/platform.js';
script.async = true;
document.body.appendChild(script);

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AmplifyProvider>
    <App
      appState={appState}
      userState={userState}
      ratingState={ratingState}
    />
  </AmplifyProvider>
);

const appHeightAndWidth = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  doc.style.setProperty('--app-width', `${window.innerWidth}px`);
}
window.addEventListener('resize', appHeightAndWidth);
appHeightAndWidth();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
