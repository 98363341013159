import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UserState } from '../state';
import '../Styles.scss';

interface LoginPageProps {
    userState: UserState;
}

export const LoginPage = observer((props: LoginPageProps) => {
    const handleLogin = () => {
        props.userState.login();
    }

    return (
        <div style={{ height: 'calc(var(--app-height))' }}>
            <div style={{
                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6) calc(50px), transparent 100%)',
                height: '300px', width: '100%', position: 'fixed', top: '0', padding: '10px 0px'
            }}>
                <Typography
                    fontWeight={'900'}
                    letterSpacing={'0.16em'}
                    fontSize={'1.6em'}
                    fontFamily={'Italiana'}
                    textAlign={'center'}
                    style={{ marginTop: '40px' }}>
                    The Manhattan Project
                </Typography>
                <div className='flex-row' style={{ width: '100%', padding: '30px', justifyContent: 'center' }}>
                    <Button onClick={handleLogin}>
                        <img src='./btn_google_signin_dark_normal_web@2x.png' alt='Google Sign In button'
                            className='googleSignIn'
                            style={{ height: '45px', width: '190px' }} />
                    </Button>
                </div>
                {props.userState.errorMessage &&
                    <Typography textAlign={'center'} sx={{ backgroundColor: 'error.dark', margin: '20px', padding: '10px' }}>{props.userState.errorMessage}</Typography>
                }
            </div>
        </div>
    );
});
