/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://hwfdd4pmgnffvhujjbupks2l5m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:62b06291-ddba-49fd-b7e4-d7ca467e40e0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BWHPMgsig",
    "aws_user_pools_web_client_id": "59rqn1pf1qk604mj3k0fkoqm1s",
    "oauth": {
        "domain": "manhattanproject-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://burcu-cal.link/,https://www.burcu-cal.link/,https://www.manhattan.burcu-cal.link/,https://localhost:3000/,https://somesome.burcu-cal.link/",
        "redirectSignOut": "http://localhost:3000/,https://www.manhattan.burcu-cal.link/,https://www.burcu-cal.link/,https://burcu-cal.link/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "manhattanRatingMap-dev": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "manhattanRatingMap-dev"
            },
            "search_indices": {
                "items": [
                    "manhattanindex-dev"
                ],
                "default": "manhattanindex-dev"
            }
        }
    }
};


export default awsmobile;
