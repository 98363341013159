import { Button, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Rating, Result } from '../models';
import { AppState, RatingState } from '../state';
import { ItemRating } from './itemRating';

interface UpdateRatingProps {
    ratingState: RatingState;
    appState: AppState;
    onHandleClose: () => void;
    ratings: Rating[];
    onHandleResult: (result: Result) => void;
    onIsLoading: (isLoading: boolean) => void;
}

export const UpdateRating = observer((props: UpdateRatingProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ratings, setRatings] = useState<Rating[]>(props.ratings);
    const [notes, setNotes] = useState<string>('');
    const handleClose = () => {
        props.onHandleClose();
    }

    useEffect(() => {
        if (notes.length === 0 && ratings.length > 0) {
            setNotes(ratings.find(r => r.notes && r.notes.length > 0)?.notes || '');
        }
    }, ratings);

    const handleRatingChange = (itemId: string, newRating: number | null) => {
        // Please don't judge me for this setTimeout. There is a race condition and this is how I have shamefully decided to adress it. #sorrynotsorry
        setTimeout(() =>
            setRatings(ratings.map(r => {
                return {
                    ...r,
                    rating: itemId === r.item.id ? newRating : r.rating
                }
            })), 50);
    }

    const handleNotesChange = (event) => {
        const newNotes = event.target.value;
        setNotes(newNotes);
    }

    const handleSave = () => {
        saveRatings();
    }

    const saveRatings = async () => {
        const updatedRatings = ratings.map(r => ({ ...r, notes: notes }));
        setIsLoading(true);
        props.onIsLoading(true);
        const ratingResults: Result[] = [];
        for (const rating of updatedRatings) {
            const addedRatingResult = await props.ratingState.addRating(rating);
            if (addedRatingResult) {
                ratingResults.push(addedRatingResult);
            }
        }
        const aggregatedResult: Result = ratingResults.every(rr => rr.status === 'success')
            ? { status: 'success', message: ratingResults[0].message }
            : { status: 'error', message: ratingResults.filter(rr => rr.status === 'error')[0].message };

        props.onHandleResult(aggregatedResult);
        setIsLoading(false);
        props.onIsLoading(false);
        if (aggregatedResult.status === 'success') {
            handleClose();
        }
    }

    return (
        <>
            {
                ratings.map(r =>
                    <ItemRating
                        key={`rating-${r.item.id}`}
                        isDisabled={isLoading}
                        title={r.item.name}
                        rating={r.rating || 0}
                        size={'large'}
                        handleRatingChange={(rating) => handleRatingChange(r.item.id, rating)}
                        name={`rating-for-item-${r.item.name}`}
                        mobileType={props.appState.mobileType}
                    />)
            }
            <TextField
                id='restaurant-notes-text-field'
                label='Add Notes'
                multiline
                rows={2}
                value={notes}
                onChange={handleNotesChange}
            />
            <div className='flex-row' style={{ gap: '20px' }}>
                <Button onClick={handleClose} variant='outlined' color='primary' style={{ flex: '1' }}>Close</Button>
                <Button
                    disabled={
                        isLoading ||
                        !props.ratingState.selectedRestaurant?.name ||
                        props.ratingState.selectedRestaurant?.name === '' ||
                        (props.ratingState.selectedRestaurant?.id === 'placeholder' && ratings.every(r => !r.rating || r.rating === 0))
                    }
                    onClick={handleSave}
                    variant='contained'
                    color='secondary'
                    style={{ flex: '1' }}>
                    Save
                </Button>
            </div>
            {/* </div> */}
        </>
    )
});
