import { Button, Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AppState, RatingState } from '../state';
import { getMap, getPlacesService, getRestaurantFromPlace } from '../util';
import { Restaurant } from '../models';
import { MemoizedGoogleMap } from './memoizedGoogleMap';
import '../Styles.scss';
import { Subject, filter } from 'rxjs';

interface MapPageProps {
    appState: AppState;
    ratingState: RatingState;
    handleSelectedRestaurant: (restaurant: Restaurant | null | undefined) => void;
}

export const MapPage = observer((props: MapPageProps) => {
    const [suggestionPlaceResults, setSuggestionPlaceResults] = useState<google.maps.places.PlaceResult[]>([]);
    const [suggestedRestaurant, setSuggestedRestaurant] = useState<Restaurant | null>(null);
    const [numSuggestions, setNumSuggestions] = useState<number>(0);
    const [typesInPriorityOrder, setTypesInPriorityOrder] = useState<string[]>(['bar', 'restaurant', 'hotel', 'establishment']);

    useEffect(() => {
        if (!props.appState.isUsingCurrentLocation || !props.appState.center || !props.appState.isMapReady) {
            return;
        }
        const map = getMap();
        if (map) {
            console.log('Updating current position');
            if (window['currentMarker']) {
                google.maps.event.clearInstanceListeners(window['currentMarker'] as google.maps.Marker);
                window['currentMarker'] = undefined;
            }
            const marker = new google.maps.Marker({
                icon: './blueDotSmall.png',
                position: props.appState.center,
                zIndex: 1000,
                map: map
            });
            window['currentMarker'] = marker;
            map!.panTo(props.appState.center);
        }
        if (numSuggestions < 3 && props.appState.isUsingCurrentLocation) {
            suggestEstablishment(props.appState.center);
        }
    }, [props.appState.center, props.appState.isUsingCurrentLocation, props.appState.isMapReady]);

    const suggestEstablishment = async (pos: { lat: number, lng: number }) => {
        if (typesInPriorityOrder.length === 0) {
            return;
        }

        // If there are any places returned from the last query search for the current type, iterate through that list first before
        // making another API call to search for places for the next type category.
        if (suggestionPlaceResults.length > 0) {
            const suggestionPlaceResultsCopy = suggestionPlaceResults;
            const suggestionToShow = suggestionPlaceResultsCopy.shift();
            setSuggestionPlaceResults(suggestionPlaceResultsCopy);
            showSuggestion(suggestionToShow!);
            return;
        }
        const placesService = getPlacesService();
        if (!placesService) {
            return;
        }
        console.log(`Suggesting closest bar/restaurant/hotel, suggestion #${numSuggestions + 1}`);

        const shouldKeepSearching$: Subject<boolean> = new Subject<boolean>();
        shouldKeepSearching$.pipe(filter(s => s === true)).subscribe(s => {
            const copyTypesInPriorityOrder = typesInPriorityOrder;
            const typeToSearch = copyTypesInPriorityOrder.shift();
            setTypesInPriorityOrder(copyTypesInPriorityOrder);
            placesService.nearbySearch(
                {
                    location: pos,
                    radius: 50,
                    type: typeToSearch
                },
                (placeResult) => {
                    if (!placeResult || placeResult.length === 0 && typesInPriorityOrder.length > 0) {
                        shouldKeepSearching$.next(true);
                    } else {
                        const suggestionToShow = placeResult.shift();
                        setSuggestionPlaceResults(placeResult);
                        showSuggestion(suggestionToShow!);
                    }
                });
        });

        // Kick off the first search
        shouldKeepSearching$.next(true);
    }

    const showSuggestion = (placeResult: google.maps.places.PlaceResult) => {
        setSuggestedRestaurant(getRestaurantFromPlace(placeResult));
        setNumSuggestions(numSuggestions + 1);
    }

    const onSuggestionAccepted = () => {
        if (props.handleSelectedRestaurant && suggestedRestaurant) {
            props.handleSelectedRestaurant(suggestedRestaurant);
        }
        setSuggestedRestaurant(null);
    }

    const onSuggestionDeclined = () => {
        setSuggestedRestaurant(null);
        if (numSuggestions < 3) {
            suggestEstablishment(props.appState.center!);
        }
    }

    return (
        <div
            style={{
                height: 'var(--app-height)',
                width: 'var(--app-width)',
                display: props.appState.isMapShown ? 'block' : 'none'
            }}>
            {suggestedRestaurant &&
                <div className='flex-row' style={{
                    position: 'fixed', top: '0', zIndex: '100'
                }}>
                    <Paper className='suggestion-box'>
                        <div>Are you at {suggestedRestaurant?.name}?</div>
                        <div className='flex-row' style={{ gap: '20px', marginTop: '20px' }}>
                            <Button
                                onClick={onSuggestionAccepted}
                                variant='outlined'
                                style={{ flex: '1' }}>
                                Yes
                            </Button>
                            <Button
                                onClick={onSuggestionDeclined}
                                variant='outlined'
                                style={{ flex: '1' }}>
                                No
                            </Button>
                        </div>
                    </Paper>
                </div>
            }
            {props.appState.center &&
                <MemoizedGoogleMap
                    appState={props.appState}
                    handleSelectedRestaurant={props.handleSelectedRestaurant}
                />
            }
        </div>
    );
});
