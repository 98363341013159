import { memo } from 'react';
import { Button } from '@mui/material';
import { AddCircleOutlined, ViewListOutlined } from '@mui/icons-material';
import { LogoutButton } from './logoutButton';
import { UserState } from '../state';
import '../Styles.scss';

interface MapPageBottomProps {
    userState: UserState;
    onMapCancel: () => void;
    onAddManual: () => void;
}

const propsAreEqual = (prevProps: MapPageBottomProps, nextProps: MapPageBottomProps): boolean => {
    return true;
}

export const MapPageBottom = memo((props: MapPageBottomProps) => {
    return (
        <div className='flex-row' style={{
            position: 'fixed', bottom: '16px', gap: '16px', padding: '0px 16px', width: '100%', zIndex: '100'
        }}>
            <Button onClick={props.onMapCancel} color={'warning'} variant={'contained'} startIcon={<ViewListOutlined />}
            >
                Rankings
            </Button>
            <Button onClick={props.onAddManual} color={'warning'} variant={'contained'} startIcon={<AddCircleOutlined />}>
                Add Manual
            </Button>
            {props.userState.currentToken &&
                <div className='flex-row' style={{ alignSelf: 'flex-end', flex: '1', justifyContent: 'flex-end' }}>
                    <LogoutButton userState={props.userState} />
                </div>
            }
        </div>
    );
}, propsAreEqual);
