import { Alert, AlertColor, Backdrop, CircularProgress, CssBaseline, Snackbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AdminPage, HomeComponent, MapPage } from './components';
import { AppState, RatingState, UserState } from './state';
import { Restaurant, Result } from './models';
import './App.scss';
import './Styles.scss';
import { useState } from 'react';

interface AppProps {
  appState: AppState;
  userState: UserState;
  ratingState: RatingState;
}

const App = (props: AppProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');

  const onHandleResult = (result: Result) => {
    setToastMessage(result.message);
    setToastSeverity(result.status as AlertColor);
    setIsToastOpen(true);
  }

  const handleToastClose = () => {
    setIsToastOpen(false);
  }

  const handleIsLoading = (isLoadingChanged: boolean) => {
    setIsLoading(isLoadingChanged);
  }

  const handleSelectedRestaurant = (restaurant: Restaurant | null | undefined) => {
    const existingRestaurant = props.ratingState.restaurants.find(r => r.name === restaurant?.name);
    if (existingRestaurant) {
      props.ratingState.setSelectedRestaurant(existingRestaurant);
    } else {
      props.ratingState.setSelectedRestaurant(restaurant);
    }
  }

  const onShowMap = (showMap: boolean) => {
    props.appState.setIsMapShown(showMap);
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={props.appState.theme}>
        <CssBaseline />
        <Routes>
          <Route path='/' element={
            <>
              <Backdrop
                sx={{ zIndex: 4000 }}
                open={isLoading && !isToastOpen}>
                <CircularProgress thickness={6} size={'100px'} sx={{ zIndex: 4001 }} />
              </Backdrop>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={isToastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                style={{ top: 'calc(var(--app-height)/2)' }}
              >
                <Alert
                  onClose={handleToastClose}
                  severity={toastSeverity}
                  sx={{ boxShadow: 10, opacity: 1, fontSize: '1.2rem', padding: '20px' }}>
                  {toastMessage}
                </Alert>
              </Snackbar>
              <div className='flex-column'
                style={{
                  gap: '12px',
                  filter: isLoading ? 'blur(2px)' : '',
                  WebkitFilter: isLoading ? 'blur(2px)' : '',
                  opacity: isLoading ? '0.2' : '1'
                }}>
                {props.userState.currentUser &&
                  <MapPage
                    appState={props.appState}
                    ratingState={props.ratingState}
                    handleSelectedRestaurant={handleSelectedRestaurant}
                  />
                }
                <HomeComponent
                  appState={props.appState}
                  ratingState={props.ratingState}
                  userState={props.userState}
                  handleSelectedRestaurant={handleSelectedRestaurant}
                  onShowMap={onShowMap}
                  onHandleResult={onHandleResult}
                  onIsLoading={handleIsLoading}
                />
              </div>
            </>
          }>
          </Route>
          {/* <Route path='/admin' element={
            <AdminPage
              appState={props.appState}
              userState={props.userState} />
          }>
          </Route> */}
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default observer(App);
