/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      email
      name
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      email
      name
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      email
      name
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRestaurant = /* GraphQL */ `
  subscription OnCreateRestaurant(
    $filter: ModelSubscriptionRestaurantFilterInput
  ) {
    onCreateRestaurant(filter: $filter) {
      id
      name
      searchResultTitle
      lat
      long
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRestaurant = /* GraphQL */ `
  subscription OnUpdateRestaurant(
    $filter: ModelSubscriptionRestaurantFilterInput
  ) {
    onUpdateRestaurant(filter: $filter) {
      id
      name
      searchResultTitle
      lat
      long
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRestaurant = /* GraphQL */ `
  subscription OnDeleteRestaurant(
    $filter: ModelSubscriptionRestaurantFilterInput
  ) {
    onDeleteRestaurant(filter: $filter) {
      id
      name
      searchResultTitle
      lat
      long
      createdAt
      updatedAt
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem($filter: ModelSubscriptionItemFilterInput) {
    onCreateItem(filter: $filter) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem($filter: ModelSubscriptionItemFilterInput) {
    onUpdateItem(filter: $filter) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem($filter: ModelSubscriptionItemFilterInput) {
    onDeleteItem(filter: $filter) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRating = /* GraphQL */ `
  subscription OnCreateRating($filter: ModelSubscriptionRatingFilterInput) {
    onCreateRating(filter: $filter) {
      id
      userId
      user {
        id
        email
        name
        avatar
        createdAt
        updatedAt
      }
      restaurantId
      restaurant {
        id
        name
        searchResultTitle
        lat
        long
        createdAt
        updatedAt
      }
      itemId
      item {
        id
        name
        createdAt
        updatedAt
      }
      rating
      notes
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRating = /* GraphQL */ `
  subscription OnUpdateRating($filter: ModelSubscriptionRatingFilterInput) {
    onUpdateRating(filter: $filter) {
      id
      userId
      user {
        id
        email
        name
        avatar
        createdAt
        updatedAt
      }
      restaurantId
      restaurant {
        id
        name
        searchResultTitle
        lat
        long
        createdAt
        updatedAt
      }
      itemId
      item {
        id
        name
        createdAt
        updatedAt
      }
      rating
      notes
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRating = /* GraphQL */ `
  subscription OnDeleteRating($filter: ModelSubscriptionRatingFilterInput) {
    onDeleteRating(filter: $filter) {
      id
      userId
      user {
        id
        email
        name
        avatar
        createdAt
        updatedAt
      }
      restaurantId
      restaurant {
        id
        name
        searchResultTitle
        lat
        long
        createdAt
        updatedAt
      }
      itemId
      item {
        id
        name
        createdAt
        updatedAt
      }
      rating
      notes
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAllowedUser = /* GraphQL */ `
  subscription OnCreateAllowedUser(
    $filter: ModelSubscriptionAllowedUserFilterInput
  ) {
    onCreateAllowedUser(filter: $filter) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAllowedUser = /* GraphQL */ `
  subscription OnUpdateAllowedUser(
    $filter: ModelSubscriptionAllowedUserFilterInput
  ) {
    onUpdateAllowedUser(filter: $filter) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAllowedUser = /* GraphQL */ `
  subscription OnDeleteAllowedUser(
    $filter: ModelSubscriptionAllowedUserFilterInput
  ) {
    onDeleteAllowedUser(filter: $filter) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
