import { Popover, TableContainer } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState, useRef, useEffect } from 'react';
import { OverallRating, Result } from '../models';
import { AppState, RatingState, UserState } from '../state';
import '../Styles.scss';
import { RatingListItem } from './ratingListItem';
import { RatingDetail } from './ratingDetail';
import { MobileOSType } from '../util';

interface RatingsListProps {
    overallRatings: OverallRating[];
    ratingState: RatingState;
    appState: AppState;
    userState: UserState;
    onPopupVisibilityChanged?: (isVisible: boolean) => void;
    style?: any;
    onHandleResult: (result: Result) => void;
    onIsLoading: (isLoading: boolean) => void;
}

export const RatingsList = observer((props: RatingsListProps) => {
    const [addRatingAnchorEl, setAddRatingAnchorEl] = useState<HTMLButtonElement | null>(null);
    const addRatingsRef = useRef(null);

    const isAddRatingOpen = Boolean(addRatingAnchorEl);
    const addRatingPopoverId = isAddRatingOpen ? 'add-rating-popover' : undefined;

    const handleAddRatingOpen = () => {
        setAddRatingAnchorEl(addRatingsRef.current);
    };

    const handleAddRatingClose = () => {
        props.ratingState.setSelectedRestaurant(null);
        setAddRatingAnchorEl(null);
    };

    const handleRatingClicked = (rating: OverallRating) => {
        props.ratingState.setSelectedRestaurant(rating.restaurant);
        handleAddRatingOpen();
    };

    useEffect(() => {
        if (props.onPopupVisibilityChanged) {
            props.onPopupVisibilityChanged(isAddRatingOpen);
        }
    }, [addRatingAnchorEl]);

    return (
        <>
            <div ref={addRatingsRef}>
                <Popover
                    id={addRatingPopoverId}
                    open={isAddRatingOpen}
                    anchorEl={addRatingAnchorEl}
                    onClose={handleAddRatingClose}
                    anchorReference={'none'}
                    anchorPosition={{ top: 140, left: 0 }}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    PaperProps={{
                        style: { width: '100%', maxWidth: props.appState.mobileType === MobileOSType.Other ? '600px' : 'calc(var(--app-width) - 36px' },
                    }}
                >
                    {props.ratingState.selectedRestaurant &&
                        <RatingDetail
                            appState={props.appState}
                            userState={props.userState}
                            ratingState={props.ratingState}
                            onHandleClose={handleAddRatingClose}
                            onHandleResult={props.onHandleResult}
                            onIsLoading={props.onIsLoading}
                        />
                    }
                </Popover>
            </div>
            <TableContainer style={{ ...props.style, maxHeight: 'calc(var(--app-height) - 216px)' }}>
                <div className='flex-column' style={{ gap: '5px', padding: '10px' }}>
                    {
                        props.overallRatings.map(rating =>
                            <div key={`rating-div-${rating.restaurant.id}`} onClick={(e) => handleRatingClicked(rating)}>
                                <RatingListItem key={`rating-${rating.restaurant.id}`} rating={rating} ratingState={props.ratingState} />
                            </div>
                        )
                    }
                </div>
            </TableContainer>
        </>
    );
});
