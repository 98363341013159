import { AddCircleOutlined, DeleteOutlined, HomeOutlined } from '@mui/icons-material';
import { Alert, AlertColor, Button, Fab, Paper, Popover, Snackbar, TableContainer, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllowedUser, Result } from '../models';
import { AppState, UserState } from '../state';
import '../Styles.scss';
import { MobileOSType } from '../util';

interface AdminPageProps {
    appState: AppState;
    userState: UserState;
    onHomeClicked: () => void;
}

export const AdminPage = observer((props: AdminPageProps) => {
    const [addUserAnchorEl, setAddUserAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<AllowedUser | undefined>(undefined);
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');
    const addUsersRef = useRef(null);
    const navigate = useNavigate();

    const isAddUserOpen = Boolean(addUserAnchorEl);
    const addUserPopoverId = isAddUserOpen ? 'add-user-popover' : undefined;

    const handleAddUserOpen = () => {
        setAddUserAnchorEl(addUsersRef.current);
    };

    const handleAddUserClose = () => {
        setAddUserAnchorEl(null);
    };

    const handleUserEmailChange = (event) => {
        const email = event.target.value;
        setUserEmail(email);
    }

    const handleAddNewUser = () => {
        setUser(undefined);
        setUserEmail(undefined);
        handleAddUserOpen();
    }

    const handleUserClicked = (user: AllowedUser) => {
        setUser(user);
        setUserEmail(user.email);
        handleAddUserOpen();
    }

    const onHandleResult = (result: Result) => {
        setToastMessage(result.message);
        setToastSeverity(result.status as AlertColor);
        setIsToastOpen(true);
    }

    const handleToastClose = () => {
        setIsToastOpen(false);
    }

    const returnToHome = () => {
        //navigate('/');
        props.onHomeClicked();
    }

    const handleDeleteUser = async (user: AllowedUser) => {
        const confirmDelete = confirm('Are you sure you want to remove this user from the allow list?');
        if (confirmDelete) {
            const result = await props.userState.deleteAllowedUser(user);
            if (result) {
                onHandleResult(result);
            }
            setUser(undefined);
            setUserEmail(undefined);
        }
    }

    const handleSave = async () => {
        let result: Result | undefined = undefined;
        if (user && userEmail) {
            result = await props.userState.updateAllowedUser({
                ...user,
                email: userEmail
            });
        }
        if (!user && userEmail) {
            if (props.userState.allowedUsers.find(u => u.email === userEmail)) {
                result = {
                    status: 'error',
                    message: 'This user e-mail already exists'
                };
                return;
            } else {
                result = await props.userState.addAllowedUser(userEmail);
            }
        }
        if (result) {
            onHandleResult(result);
        }
        handleAddUserClose();
    }

    return (
        <>
            {props.userState.isUserAdmin === undefined ?
                <Typography textAlign={'center'} sx={{ margin: '20px', padding: '10px' }}>Loading...</Typography>
                :
                !props.userState.isUserAdmin ?
                    <Typography textAlign={'center'} sx={{ backgroundColor: 'error.dark', margin: '20px', padding: '10px' }}>Unauthorized</Typography>
                    :
                    <>
                        <div id='admin-page-div' style={{
                            height: 'calc(var(--app-height))'
                        }}>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                                open={isToastOpen}
                                autoHideDuration={4000}
                                onClose={handleToastClose}
                                style={{ top: 'calc(var(--app-height)/2)' }}
                            >
                                <Alert
                                    onClose={handleToastClose}
                                    severity={toastSeverity}
                                    sx={{ boxShadow: 10, opacity: 1, fontSize: '1.2rem', padding: '20px' }}>
                                    {toastMessage}
                                </Alert>
                            </Snackbar>
                            <div style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                height: 'calc(var(--app-height))', position: 'fixed', top: '0', padding: '10px 0px',
                                left: props.appState.mobileType === MobileOSType.Other ? 'calc(calc(var(--app-width) - 640px)/2)' : '0',
                                maxWidth: props.appState.mobileType === MobileOSType.Other ? '640px' : '100%',
                                width: '100%'
                            }}>
                                <div ref={addUsersRef}>
                                    <Popover
                                        id={addUserPopoverId}
                                        open={isAddUserOpen}
                                        anchorEl={addUserAnchorEl}
                                        onClose={handleAddUserClose}
                                        anchorReference={'none'}
                                        anchorPosition={{ top: 140, left: 0 }}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        PaperProps={{
                                            style: { width: '100%', maxWidth: props.appState.mobileType === MobileOSType.Other ? '600px' : 'calc(var(--app-width) - 36px' },
                                        }}
                                    >
                                        <Paper id='rating-detail-container-paper'>
                                            <div className='flex-column' id='rating-detail-container-div'
                                                style={{
                                                    padding: '20px',
                                                    gap: '10px'
                                                }}>
                                                <Typography
                                                    fontSize={'1.4em'}>
                                                    Add/Update User
                                                </Typography>
                                                <TextField
                                                    id='add-or-update-allowed-users'
                                                    label='Email'
                                                    value={userEmail}
                                                    onChange={handleUserEmailChange}
                                                />
                                                <div className='flex-row' style={{ gap: '20px' }}>
                                                    <Button onClick={handleAddUserClose} variant='outlined' color='primary' style={{ flex: '1' }}>Cancel</Button>
                                                    <Button
                                                        disabled={!userEmail}
                                                        onClick={handleSave}
                                                        variant='contained'
                                                        color='secondary'
                                                        style={{ flex: '1' }}>
                                                        Add/Update
                                                    </Button>
                                                </div>
                                            </div>
                                        </Paper>
                                    </Popover>
                                </div>

                                <Typography
                                    fontWeight={'900'}
                                    letterSpacing={'0.16em'}
                                    fontSize={'1.6em'}
                                    fontFamily={'Italiana'}
                                    textAlign={'center'}
                                    textTransform={'capitalize'}
                                    style={{ marginTop: '20px' }}>
                                    Manage Allowed Users
                                </Typography >
                                <TableContainer style={{ maxHeight: 'calc(var(--app-height) - 150px)' }}>
                                    <div className='flex-column' style={{ gap: '10px', padding: '20px' }}>
                                        {
                                            props.userState.allowedUsers.map(user =>
                                                <div key={`user-div-${user.id}`}
                                                    style={{ border: '2px solid white', padding: '10px' }}
                                                    className='flex-row'
                                                >
                                                    <div key={`user-${user.id}`} style={{ flex: '1', alignSelf: 'center' }}
                                                        onClick={(e) => handleUserClicked(user)}>
                                                        {user.email}
                                                    </div>
                                                    <Fab size='small' color='secondary' aria-label='Button to delete allow listed user'
                                                        sx={{ backgroundColor: 'rgba(69, 19, 4)' }}
                                                        onClick={() => handleDeleteUser(user)}>
                                                        <DeleteOutlined />
                                                    </Fab>
                                                </div>
                                            )
                                        }
                                    </div>
                                </TableContainer>
                            </div>
                        </div>

                        <div className='flex-row' style={{
                            position: 'fixed', bottom: '16px', gap: '16px', padding: '0px 16px', width: '100%', zIndex: '100'
                        }}>
                            <Button onClick={handleAddNewUser} color={'warning'} variant={'contained'} startIcon={<AddCircleOutlined />}>
                                Add User
                            </Button>
                            <Fab size='small' color='secondary' aria-label='home button'
                                sx={{ backgroundColor: 'rgba(69, 19, 4)', alignSelf: 'flex-end', marginLeft: 'auto' }}
                                onClick={returnToHome}>
                                <HomeOutlined />
                            </Fab>
                        </div>
                    </>
            }
        </>
    );
});
