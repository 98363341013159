import { Input } from '@mui/material';
import { MyLocation, Search } from '@mui/icons-material';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { AppState } from '../state';
import { getMap, getPlacesService, getRestaurantFromPlace, newYork } from '../util';
import { Restaurant } from '../models';
import '../Styles.scss';

import { GoogleMap, useJsApiLoader, LoadScriptProps, Autocomplete } from '@react-google-maps/api';
const googleMapsLibraries: LoadScriptProps['libraries'] = ['places'];

interface MapControlProps {
    position: keyof typeof google.maps.ControlPosition;
    map: google.maps.Map | null;
}
const MapControl = (props: React.PropsWithChildren<MapControlProps>) => {
    const ref = useRef<any>();
    useEffect(() => {
        if (props.map && ref && ref.current) {
            props.map.controls[window.google.maps.ControlPosition[props.position]].push(
                ref.current
            );
        }
    }, [props.map, ref]);
    return <div ref={ref}>{props.children}</div>;
};

interface MemoizedGoogleMapProps {
    appState: AppState;
    handleSelectedRestaurant: (restaurant: Restaurant | null | undefined) => void;
}

const propsAreEqual = (prevProps: MemoizedGoogleMapProps, nextProps: MemoizedGoogleMapProps): boolean => {
    if (prevProps.appState.center === undefined && nextProps.appState.center !== undefined) {
        return false;
    }
    if (prevProps.appState.center !== undefined && nextProps.appState.center === undefined) {
        return false;
    }
    if (prevProps.appState.center?.lat !== nextProps.appState.center?.lat ||
        prevProps.appState.center?.lng !== nextProps.appState.center?.lng) {
        return false;
    }
    return true;
}

export const MemoizedGoogleMap = memo((props: MemoizedGoogleMapProps) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        version: '3',
        googleMapsApiKey: 'AIzaSyCGFieC3UJiGUgcBCvylp-VuO7dHLDbrO8',
        libraries: googleMapsLibraries
    });

    const onLoad = useCallback((newMap: google.maps.Map) => {
        initializeMap(newMap);
    }, []);

    const initializeMap = async (newMap: google.maps.Map) => {
        props.appState.setIsMapReady(false);
        console.log('Initializing map');
        window['memoizedMap'] = newMap;
        setMap(newMap);
        const newPlacesService = new google.maps.places.PlacesService(document.createElement('div'));
        window['placesService'] = newPlacesService;
        props.appState.setIsMapReady(true);
        props.appState.setIsMapShown(true);
    };

    const onAutoCompleteLoad = useCallback((newAutoComplete: google.maps.places.Autocomplete) => {
        setAutoComplete(newAutoComplete);
    }, []);

    const onPlaceChanged = () => {
        if (!autoComplete) {
            return;
        }
        const place = autoComplete.getPlace();
        const restaurant = getRestaurantFromPlace(place);

        const map = getMap();
        if (map) {
            const marker = new google.maps.Marker({
                position: { lat: place.geometry!.location!.lat(), lng: place.geometry!.location!.lng() },
                map: map
            });
            marker.addListener('click', ({ domEvent, latLng }) => {
                props.handleSelectedRestaurant(restaurant);
            });
            window['mapMarkers'].push(marker);
        }

        map?.panTo({ lat: place.geometry!.location!.lat(), lng: place.geometry!.location!.lng() });
        map?.setZoom(15);
        props.handleSelectedRestaurant(restaurant);

        if (autoComplete) {
            setSearchValue('');
        }
    }

    const handleOnUpdateCurrentLocation = async () => {
        await props.appState.getCurrentLocation();
        if (props.appState.center) {
            map?.panTo(props.appState.center);
        }
    }

    const handleSearchValueChange = (event) => {
        setSearchValue(event.target.value);
    }

    const onMapClick = (event) => {
        const placesService = getPlacesService();
        if (event.placeId && placesService) {
            placesService.getDetails({
                fields: ['geometry', 'name'],
                placeId: event.placeId
            }, (result: google.maps.places.PlaceResult | null, b) => {
                if (result) {
                    props.handleSelectedRestaurant(getRestaurantFromPlace(result));
                }
            });
        }
    }

    const onUnmount = useCallback((map) => {
        setMap(null);
        window['memoizedMap'] = undefined;
    }, []);

    return <>
        {props.appState.center && isLoaded &&
            <GoogleMap
                zoom={props.appState.center!.lat === newYork.lat ? 13 : 17}
                center={props.appState.center}
                mapContainerClassName='map-container'
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={onMapClick}
                options={
                    {
                        fullscreenControl: false,
                        zoomControlOptions: {
                            position: 3.0
                        },
                        mapTypeControl: false,
                        streetViewControl: false
                    }
                }
            >
                <MapControl map={map} position='RIGHT_TOP'>
                    <button className='map-button' onClick={handleOnUpdateCurrentLocation}>
                        <div className='flex-row' style={{ justifyContent: 'center' }}>
                            <MyLocation />
                        </div>
                    </button>
                </MapControl>
                <Autocomplete
                    className='map-search-autocomplete'
                    fields={['geometry', 'name']}
                    // types={['establishment']}
                    onPlaceChanged={onPlaceChanged}
                    onLoad={onAutoCompleteLoad}
                >
                    <Input
                        disableUnderline={true}
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        className='map-search-bar rounded-input'
                        id='mapSearchInput'
                        startAdornment={<Search sx={{ marginRight: '10px' }} />}
                        placeholder={'Search'}
                    />
                </Autocomplete>
            </GoogleMap>
        }
    </>;
}
    , propsAreEqual);
