import { Observable, ReplaySubject, Subject, Subscription, take, takeUntil } from 'rxjs';
import { AllowedUser, Result, User } from '../models';
import { createAllowedUser, createUser, deleteAllowedUser, findUser, getAllowedUsers, updateAllowedUser, updateUser } from './graphQLOperations';
import * as subscriptions from '../graphql/subscriptions';
import { Hub, Auth, API, graphqlOperation } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types';

interface ProfileAttributes {
    email: string;
    given_name: string;
    family_name: string;
    picture: string;
}

export class UserService {
    readonly clientId = '458080645921-l9plak0td16srphscp54r5hqf3tlhkoe.apps.googleusercontent.com';
    currentUser$ = new ReplaySubject<User | undefined | null>();
    currentToken$ = new ReplaySubject<any | undefined | null>();
    allUsers$ = new ReplaySubject<User[]>();
    private allowedUersSubject$ = new ReplaySubject<AllowedUser[]>();
    private isUserAdminSubject$ = new ReplaySubject<boolean>();
    private changesSubscriptions$: Subscription[] = [];
    errors$ = new ReplaySubject<string>();
    private currentUser: User | null | undefined;
    private allowedUsers: AllowedUser[] = [];

    constructor(private stop$: Subject<boolean>) {
        Hub.listen('auth', ({ payload }) => {
            if (payload.event === 'signIn') {
                return this.getUser(true);
            }
            if (payload.event === 'signOut') {
                this.updateCurrentUserFromToken(null);
            }
            if (payload.event === 'signIn_failure') {
                const payloadData = payload.data.toString();
                const errorMessage = decodeURIComponent(
                    payloadData.substring('Error: PreSignUp+failed+with+error+'.length, payloadData.length - 1)
                        .replace(/\+/g, ' '));
                console.error(errorMessage);
                this.errors$.next(errorMessage);
            }
        });
        this.getUser(false);

        this.currentUser$.pipe(
            takeUntil(stop$))
            .subscribe(user => {
                this.fetchCollections();
            });

        this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onCreateAllowedUser)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleAllowedUserChange(provider, value),
                error: (error) => console.warn(error)
            }));
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateAllowedUser)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleAllowedUserChange(provider, value),
                error: (error) => console.warn(error)
            }));
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteAllowedUser)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleAllowedUserChange(provider, value),
                error: (error) => console.warn(error)
            }));

        this.stop$.pipe(
            take(1)
        ).subscribe(a => {
            this.changesSubscriptions$.forEach(s => s.unsubscribe());
        });
    }

    private getUser = async (shouldCreateIfMissing: boolean = false) => {
        try {
            const token = await Auth.currentAuthenticatedUser();
            const attributes = token.attributes as ProfileAttributes;

            this.updateCurrentUserFromToken(attributes, shouldCreateIfMissing);
            this.currentToken$.next(token);
        } catch (err) {
            console.log('Did not receive a token from cognito');
            console.log(err);
            this.currentToken$.next(null);
        }
    }

    private updateCurrentUserFromToken = async (attributes: ProfileAttributes | null, shouldCreateIfMissing: boolean = false) => {
        if (!attributes) {
            this.updateCurrentUser(null);
            return;
        } else {
            try {
                const foundUser = await findUser(attributes.email);
                if (foundUser) {
                    // Update user so we have latest avatar etc
                    const updatedUser = await updateUser({
                        id: foundUser.id,
                        email: attributes.email,
                        name: attributes.given_name + ' ' + attributes.family_name,
                        avatar: attributes.picture
                    })
                    this.updateCurrentUser(updatedUser);
                } else {
                    if (shouldCreateIfMissing) {
                        console.log('Creating new user');
                        //Create user
                        const createdUser = await createUser({
                            id: 'placeholder',
                            email: attributes.email,
                            name: attributes.given_name + ' ' + attributes.family_name,
                            avatar: attributes.picture
                        });
                        if (createdUser) {
                            this.updateCurrentUser(createdUser);
                        }
                    }
                }
            } catch (err) {
                const message = `Could not check whether user exists. ${(err as any).toString()}`;
                console.log(message);
                this.errors$.next(message);
            }
        }
    }

    private updateCurrentUser = (user: User | null | undefined) => {
        this.currentUser = user;
        this.currentUser$.next(user);
    }

    login = async () => {
        try {
            await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: Math.random().toString() });
        } catch (err) {
            this.onLoginFailure(err);
        }
    }

    logout = async () => {
        try {
            await Auth.signOut();
            this.onLogoutSuccess();
        } catch (err) {
            this.onLogoutFailure(err);
        }
    }

    onLoginFailure = (err) => {
        const message = `Could not log in: ${(err as any).toString()}`;
        console.log(message);
        this.errors$.next(message);
        this.updateCurrentUser(undefined);
    }

    onLogoutSuccess = () => {
        this.updateCurrentUser(undefined);
    }

    onLogoutFailure = (err) => {
        const message = `Could not log out: ${(err as any).toString()}`;
        console.log(message);
        this.errors$.next(message);
    }

    get isUserAdmin$(): Observable<boolean> {
        return this.isUserAdminSubject$.pipe(
            takeUntil(this.stop$)
        );
    };

    get allowedUsers$(): Observable<AllowedUser[]> {
        return this.allowedUersSubject$.pipe(
            takeUntil(this.stop$)
        );
    };

    addAllowedUser = async (newUserEmail: string): Promise<Result | undefined> => {
        try {
            if (this.allowedUsers.find(au => au.email === newUserEmail)) {
                alert('This user e-mail already exists');
                return;
            }
            const createdAllowedUser = await createAllowedUser({
                email: newUserEmail
            });

            return createdAllowedUser ? { status: 'success', message: 'User added to allow list' } : { status: 'error', message: 'Could not add allowed user' };
        } catch (e) {
            console.error(e);
            return { status: 'error', message: `Could not add allowed user` };
        }
    }

    updateAllowedUser = async (updatedUser: AllowedUser): Promise<Result | undefined> => {
        try {
            const updatedAllowedUser = await updateAllowedUser(updatedUser);
            return updatedAllowedUser ? { status: 'success', message: 'User e-mail updated' } : { status: 'error', message: 'Could not update allowed user' };
        } catch (e) {
            console.error(e);
            return { status: 'error', message: `Could not update allowed user` };
        }
    }

    deleteAllowedUser = async (deletedUser: AllowedUser): Promise<Result | undefined> => {
        try {
            const deletedAllowedUser = await deleteAllowedUser(deletedUser);
            return deletedAllowedUser ? { status: 'success', message: 'User removed from allow list' } : { status: 'error', message: 'Could not remove user from allow list' };
        } catch (e) {
            console.error(e);
            return { status: 'error', message: `Could not remove user from allow list` };
        }
    }

    private handleAllowedUserChange(provider: any, value: any) {
        console.log('Allowed users changed, server is sending new values...');
        this.fetchCollections();
    }

    private fetchCollections = async () => {
        try {
            const allowedUsers = await getAllowedUsers();
            this.allowedUsers = allowedUsers;
            this.allowedUersSubject$.next(allowedUsers);
            this.isUserAdminSubject$.next(true);
        } catch (res: any) {
            if (res.errors && res.errors.length > 0 && res.errors[0].errorType === 'Unauthorized') {
                this.isUserAdminSubject$.next(false);
            } else {
                console.log(res);
            }
        }
    }
}
